/* eslint-disable global-require */
<template>
  <div class="registration">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>

    <RegistrationStep
      :step="1"/>

    <section id="first_filter">
      <div class="w1200">
        <div class="tabset">
          <!-- <div class="tab h6 active">
            依醫師
          </div> -->
          <div class="file_download" v-if="file">
            <a :href="file" class="all small" target="_blank">
              <span class="h4">{{$t('schedule')}}&emsp;</span>
              <font-awesome-icon icon="file-download" class="h5 txt-theme"/>
            </a>
          </div>

          <!-- <div style="position: relative; text-align: center;">
              <br/>
              因系統調整3/5（五） 18:00-21:00暫停網路掛號系統。造成不便，敬請見諒。
          </div> -->
          <!-- <div class="tab h6">
            依日期
          </div> -->
        </div>

        <div class="pc_doctor">
          <swiper :options="swiperOptionPc">
            <swiper-slide
              v-for="n in Math.floor(1 + DoctorData.length/8)"
              :key="n">
              <div class="filter_content">
                <div
                  class="doctor_item"
                  v-for="(item, key) in DoctorData.slice((n-1)*8 , n*8)"
                  :key="`${n}-${key}`">
                  <input
                    type="radio"
                    name="doctor"
                    class="doctor_filter"
                    :id="`doctor${n}-${key}`"
                    :value="item.memId"
                    v-model="chooseDoctor"
                    @change="initNow7day(); startScheduleFilter();">
                  <label :for="`doctor${n}-${key}`">
                    <p>
                      <template v-if="getLanguage === 'en-US'">
                        {{$t('doctor')}}
                        {{item.name}}
                      </template>
                      <template v-else>
                        {{item.name}}
                        {{$t('doctor')}}
                      </template>
                    </p>
                  </label>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev swiper-button-prev-pc"></div>
          <div class="swiper-button-next swiper-button-next-pc"></div>
          <div class="swiper-pagination swiper-pagination-pc"></div>
        </div>

        <div class="mobile_doctor">
            <div
              class="filter_content"
              v-for="(item, key) in DoctorData"
              :key="key">
              <div class="doctor_item">
                <input
                  type="radio"
                  name="doctor_m"
                  class="doctor_filter"
                  :id="`doctor_m-${key}`"
                  :value="item.memId"
                  v-model="chooseDoctor"
                  @change="initNow7day(); startScheduleFilter();">
                <label :for="`doctor_m-${key}`">
                  <p>{{item.name}} {{$t('doctor')}}</p>
                </label>
              </div>
            </div>
        </div>
      </div>
    </section>

    <transition enter-active-class="fade-enter-active" leave-active-class="fade-leave-active">
        <section id="sec_fiter" v-if="chooseDoctor != null">
          <div class="w1000">
            <!-- <div style="position: relative; top: -3em; text-align: center;" v-if="DoctorData[0].devisionId === 2">
              <br/>
              「2/18中午12點會開放2/24之後的網路掛號，您也可2/17於8:30-17:30來電宜蘊中醫診所02-2706-5277人工掛號。」<br/><br/>
            </div> -->
            <div class="top">
              <div class="select_time">
                <div
                  class="prev txt-bold txt-theme"
                  :class="{disable : nowWeek == 1}"
                  @click="changeWeek('prev')">
                    <img src="@/assets/img/Direction_button.svg" alt="">
                    <small>{{$t('lastWeek')}}</small>
                </div>
                <p>
                  {{
                    now7days[0].replaceAll('-','/')
                  }} ({{returnZhDay(new Date(now7days[0].replace(/-/g, '/')))}})
                  <span class="separation"> - </span>
                  {{
                    now7days[6].replaceAll('-','/')
                  }} ({{returnZhDay(new Date(now7days[6].replace(/-/g, '/')))}})
                </p>
                <div
                  class="next txt-bold txt-theme"
                  :class="{disable : nowWeek == 4}"
                  @click="changeWeek('next')">
                    <small>{{$t('nextWeek')}}</small>
                    <img src="@/assets/img/Direction_button.svg" alt="" style="transform: rotate(180deg);">
                </div>
              </div>
            </div>

            <div class="pc_table">
              <table class="t_table">
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{{$t('am')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('noon')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('betweenNight')}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="main_table">
                <thead>
                  <tr>
                    <td>{{now7days[0].slice(5).replace('-','/')}} ({{returnZhDay(new Date(now7days[0].replace(/-/g, '/')))}})</td>
                    <td>{{now7days[1].slice(5).replace('-','/')}} ({{returnZhDay(new Date(now7days[1].replace(/-/g, '/')))}})</td>
                    <td>{{now7days[2].slice(5).replace('-','/')}} ({{returnZhDay(new Date(now7days[2].replace(/-/g, '/')))}})</td>
                    <td>{{now7days[3].slice(5).replace('-','/')}} ({{returnZhDay(new Date(now7days[3].replace(/-/g, '/')))}})</td>
                    <td>{{now7days[4].slice(5).replace('-','/')}} ({{returnZhDay(new Date(now7days[4].replace(/-/g, '/')))}})</td>
                    <td>{{now7days[5].slice(5).replace('-','/')}} ({{returnZhDay(new Date(now7days[5].replace(/-/g, '/')))}})</td>
                    <td>{{now7days[6].slice(5).replace('-','/')}} ({{returnZhDay(new Date(now7days[6].replace(/-/g, '/')))}})</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="(item, key) in nowDoctorScheduleMap[0]" :key="key">
                      <div v-if="item != null && item.can_appointment === 'Y' && new Date() < new Date(`${item.job_day.replace(/-/g, '/')} 11:30`)">
                        <input
                          type="radio"
                          name="time"
                          class="time_filter"
                          :id="`${item.doctor_code}${item.job_day}${item.sid}`"
                          :value="`${item.doctor_code},${item.job_day},${item.sid},${item.shift_code},${item.clinic},${item.doctor_name}`"
                          v-model="chooseSid">
                        <label
                        :class="[
                        {labelEn: getLanguage === 'en-US'},
                        {labelCn: getLanguage === 'zh_CN'},
                        ]"
                        :for="`${item.doctor_code}${item.job_day}${item.sid}`"></label>
                      </div>
                      <p class="full" v-else-if="item != null && item.can_appointment === 'Y' && new Date() > new Date(`${item.job_day.replace(/-/g, '/')} 11:30`)">
                        {{$t('overTime')}}
                      </p>
                      <p class="full" v-else-if="item != null && item.can_appointment === 'N'">
                        {{item.reference_no}}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td v-for="(item, key) in nowDoctorScheduleMap[1]" :key="key">
                      <div v-if="item != null && item.can_appointment === 'Y' && new Date() < new Date(`${item.job_day.replace(/-/g, '/')} 16:30`)">
                        <input
                          type="radio"
                          name="time"
                          class="time_filter"
                          :id="`${item.doctor_code}${item.job_day}${item.sid}`"
                          :value="`${item.doctor_code},${item.job_day},${item.sid},${item.shift_code},${item.clinic},${item.doctor_name}`"
                          v-model="chooseSid">
                        <label
                        :class="[
                        {labelEn: getLanguage === 'en-US'},
                        {labelCn: getLanguage === 'zh_CN'},
                        ]"
                        :for="`${item.doctor_code}${item.job_day}${item.sid}`"></label>
                      </div>
                      <p class="full" v-else-if="item != null && item.can_appointment === 'Y' && new Date() > new Date(`${item.job_day.replace(/-/g, '/')} 16:30`)">
                        {{$t('overTime')}}
                      </p>
                      <p class="full" v-else-if="item != null && item.can_appointment === 'N'">
                        {{item.reference_no}}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td v-for="(item, key) in nowDoctorScheduleMap[2]" :key="key">
                      <div v-if="item != null && item.can_appointment === 'Y' && new Date() < new Date(`${item.job_day.replace(/-/g, '/')} 20:30`)">
                        <input
                          type="radio"
                          name="time"
                          class="time_filter"
                          :data-attr="`${new Date().toLocaleDateString} : ${new Date(`${item.job_day.replace(/-/g, '/')} 20:30`)}`"
                          :id="`${item.doctor_code}${item.job_day}${item.sid}`"
                          :value="`${item.doctor_code},${item.job_day},${item.sid},${item.shift_code},${item.clinic},${item.doctor_name}`"
                          v-model="chooseSid">
                        <label
                        :class="[
                        {labelEn: getLanguage === 'en-US'},
                        {labelCn: getLanguage === 'zh_CN'},
                        ]"
                        :for="`${item.doctor_code}${item.job_day}${item.sid}`"></label>
                      </div>
                      <p class="full" v-else-if="item != null && item.can_appointment === 'Y' && new Date() > new Date(`${item.job_day.replace(/-/g, '/')} 20:30`)">
                        {{$t('overTime')}}
                      </p>
                      <p class="full" v-else-if="item != null && item.can_appointment === 'N'">
                        {{item.reference_no}}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mobile_table">
              <div class="mobile_day_item" v-for="(item, key) in now7days" :key="key">
                <p>{{item.slice(5).replace('-','/')}} ({{returnZhDay(new Date(item.replace(/-/g, '/')))}})</p>
                <div v-if="nowDoctorScheduleMapMobile[key][0]">
                  <div v-if="nowDoctorScheduleMapMobile[key][0].can_appointment === 'Y' && new Date() < new Date(`${item.replace(/-/g, '/')} 11:30`)">
                    <input
                      type="radio"
                      name="time"
                      class="time_filter"
                      :id="`m${nowDoctorScheduleMapMobile[key][0].doctor_code}${nowDoctorScheduleMapMobile[key][0].job_day}${nowDoctorScheduleMapMobile[key][0].sid}`"
                      :value="`${nowDoctorScheduleMapMobile[key][0].doctor_code},${nowDoctorScheduleMapMobile[key][0].job_day},${nowDoctorScheduleMapMobile[key][0].sid},${nowDoctorScheduleMapMobile[key][0].shift_code},${nowDoctorScheduleMapMobile[key][0].clinic},${nowDoctorScheduleMapMobile[key][0].doctor_name},m`"
                      v-model="chooseSid">
                    <label
                      :for="`m${nowDoctorScheduleMapMobile[key][0].doctor_code}${nowDoctorScheduleMapMobile[key][0].job_day}${nowDoctorScheduleMapMobile[key][0].sid}`"
                      @click="tempMobileChooseTimeControl+=1">
                      <p>{{$t('am')}}</p><p class="txt-theme">{{$t('book')}} ></p>
                    </label>
                  </div>
                  <div class="full" v-else-if="nowDoctorScheduleMapMobile[key][0].can_appointment === 'N'">
                    <p>{{$t('am')}}</p>
                    <p>{{nowDoctorScheduleMapMobile[key][0].reference_no}}</p>
                  </div>
                  <div class="full" v-else-if="nowDoctorScheduleMapMobile[key][0].can_appointment === 'Y' && new Date() > new Date(`${item.replace(/-/g, '/')} 11:30`)">
                    <p>{{$t('am')}}</p>
                    <p>{{$t('overTime')}}</p>
                  </div>
                </div>
                <div v-if="nowDoctorScheduleMapMobile[key][1]">
                  <div v-if="nowDoctorScheduleMapMobile[key][1].can_appointment === 'Y' && new Date() < new Date(`${item.replace(/-/g, '/')} 11:30`)">
                    <input
                      type="radio"
                      name="time"
                      class="time_filter"
                      :id="`m${nowDoctorScheduleMapMobile[key][1].doctor_code}${nowDoctorScheduleMapMobile[key][1].job_day}${nowDoctorScheduleMapMobile[key][1].sid}`"
                      :value="`${nowDoctorScheduleMapMobile[key][1].doctor_code},${nowDoctorScheduleMapMobile[key][1].job_day},${nowDoctorScheduleMapMobile[key][1].sid},${nowDoctorScheduleMapMobile[key][1].shift_code},${nowDoctorScheduleMapMobile[key][1].clinic},${nowDoctorScheduleMapMobile[key][1].doctor_name},m`"
                      v-model="chooseSid">
                    <label
                      :for="`m${nowDoctorScheduleMapMobile[key][1].doctor_code}${nowDoctorScheduleMapMobile[key][1].job_day}${nowDoctorScheduleMapMobile[key][1].sid}`"
                      @click="tempMobileChooseTimeControl+=1">
                      <p>{{$t('noon')}}</p><p class="txt-theme">{{$t('book')}} ></p>
                    </label>
                  </div>
                  <div class="full" v-else-if="nowDoctorScheduleMapMobile[key][1].can_appointment === 'N'">
                    <p>{{$t('noon')}}</p>
                    <p>{{nowDoctorScheduleMapMobile[key][1].reference_no}}</p>
                  </div>
                  <div class="full" v-else-if="nowDoctorScheduleMapMobile[key][1].can_appointment === 'Y' && new Date() > new Date(`${item.replace(/-/g, '/')} 16:30`)">
                    <p>{{$t('am')}}</p>
                    <p>{{$t('overTime')}}</p>
                  </div>
                </div>
                <div v-if="nowDoctorScheduleMapMobile[key][2]">
                  <div v-if="nowDoctorScheduleMapMobile[key][2].can_appointment === 'Y' && new Date() < new Date(`${item.replace(/-/g, '/')} 20:30`)">
                    <input
                      type="radio"
                      name="time"
                      class="time_filter"
                      :id="`m${nowDoctorScheduleMapMobile[key][2].doctor_code}${nowDoctorScheduleMapMobile[key][2].job_day}${nowDoctorScheduleMapMobile[key][2].sid}`"
                      :value="`${nowDoctorScheduleMapMobile[key][2].doctor_code},${nowDoctorScheduleMapMobile[key][2].job_day},${nowDoctorScheduleMapMobile[key][2].sid},${nowDoctorScheduleMapMobile[key][2].shift_code},${nowDoctorScheduleMapMobile[key][2].clinic},${nowDoctorScheduleMapMobile[key][2].doctor_name},m`"
                      v-model="chooseSid">
                    <label
                      :for="`m${nowDoctorScheduleMapMobile[key][2].doctor_code}${nowDoctorScheduleMapMobile[key][2].job_day}${nowDoctorScheduleMapMobile[key][2].sid}`"
                      @click="tempMobileChooseTimeControl+=1">
                      <p>{{$t('betweenNight')}}</p><p class="txt-theme">{{$t('book')}} ></p>
                    </label>
                  </div>
                  <div class="full" v-else-if="nowDoctorScheduleMapMobile[key][2].can_appointment === 'N'">
                    <p>{{$t('betweenNight')}}</p>
                    <p>{{nowDoctorScheduleMapMobile[key][2].reference_no}}</p>
                  </div>
                  <div class="full" v-else-if="nowDoctorScheduleMapMobile[key][2].can_appointment === 'Y' && new Date() > new Date(`${item.replace(/-/g, '/')} 20:30`)">
                    <p>{{$t('am')}}</p>
                    <p>{{$t('overTime')}}</p>
                  </div>
                </div>
                <!-- <div class="full">
                  <p>晚上</p>
                  <p>預約 ></p>
                </div> -->
              </div>
            </div>
          </div>
        </section>
    </transition>

    <section id="registration_btnbox">
      <div class="w1000 box">
        <router-link to="/registration" class="btn btn-dark p"
        :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
        >
          {{$t('return')}}
        </router-link>
        <button type="button" class="btn p"
        :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
        v-if="!isMobile" @click="confirmHandler">
          {{$t('confirmOutpatient')}}
        </button>
      </div>
    </section>

    <div id="registration_lightbox" v-show="registrationLightboxOpen">
      <div class="cover" @click="registrationLightboxClose()"></div>
      <div class="box">
        <img src="@/assets/img/delete.svg" class="light_box_close"  @click="registrationLightboxClose()">
        <!-- <div class="registration_type">
          <div class="type" :class="{active: ifFirst}" @click="ifFirst=true">初診</div>
          <div class="type" :class="{active: !ifFirst}" @click="ifFirst=false">複診</div>
        </div> -->
        <div class="text txt-center">
          <form class="form">
            <div class="item100">
              <p>{{$t('name')}}<span class="txt-main">*</span></p>
              <input type="text" name="cname" id="cname" value="" maxlength="20"
                :placeholder="this.$t('namePlaceHolder')"
                :readonly="member.api_token"
                v-model="formData.name">
            </div>
            <div class="item100">
              <p>{{$t('date')}}<span class="txt-main">*</span></p>
              <input
                v-if="member.api_token"
                type="text"
                :readonly="member.api_token"
                v-model="formData.birthday">
              <date-pick
                v-else
                :selectableYearRange="{from: 1940, to: new Date().getUTCFullYear()}"
                :inputAttributes="{
                  id: 'birthday',
                  name:'birthday',
                  placeholder:'ex.1990/01/01',
                  readonly: true
                }"
                v-model="formData.birthday">
              </date-pick>
            </div>
            <div class="item100" v-if="!member.api_token || (member.api_token && isTaiwanId)">
              <p>{{$t('uid')}}<span class="txt-main">* ({{$t("uidInfo")}})</span></p>
              <input type="text" name="accA" maxlength="20" id="accA"
                @input="inputAccount($event)"
                :placeholder="this.$t('uid')"
                :readonly="member.api_token"
                v-model="formData.accountA">
            </div>
            <div class="item100" v-if="!member.api_token || (member.api_token && !isTaiwanId)">
              <p>{{$t('passportNum')}}<span class="txt-main">*({{$t("passportNumInfo")}})</span></p>
              <input type="text" name="accB" maxlength="20" id="accB"
                @input="inputAccount($event)"
                :placeholder="this.$t('passportNum')"
                :readonly="member.api_token"
                v-model="formData.accountB">
              <!-- <input type="text" name="accB" maxlength="20" id="accB"
                @input="inputAccount($event)"
                @click="openForeignerLightBox"
                placeholder="護照/居留證號碼"
                readonly
                v-model="formData.accountB"> -->
            </div>
            <div class="item100">
              <p>{{$t('contactNumber')}}<span class="txt-main">*</span></p>
              <div class="item_inner col100">
                <div class="col33">
                  <input
                    v-if="member.api_token"
                    type="text"
                    :readonly="member.api_token"
                    v-model="formData.country_code">
                  <select v-else name="country" id="country" v-model="formData.country_code" :readonly="member.api_token">
                    <option value="">
                      ---
                    </option>
                    <option v-for="(item, key) in countryCode"
                      :value="item.slice(1)"
                      :key="key">
                      {{item}}
                    </option>
                  </select>
                </div>
                <div class="col66">
                  <input type="text" name="mobile" id="mobile"
                    maxlength="15"
                    :placeholder="this.$t('contactPlaceholder')"
                    :readonly="member.api_token"
                    v-model="formData.phone">
                </div>
              </div>
            </div>
            <div class="item100">
              <p>E-mail<span class="txt-main">*</span></p>
              <input type="text" name="email" id="email" value=""
                :placeholder="this.$t('emailPlaceholder')"
                :readonly="member.api_token"
                v-model="formData.email">
            </div>
            <div class="item100" v-if="!member.api_token">
              <p>{{$t('verification')}}<span class="txt-main">*</span></p>
              <input type="text"
                name="verification"
                id="verification"
                v-model="formData.verify_code"
                :placeholder="verificationPlaceholder">
              <div class="verification_set" v-if="unlockStatus">
                <button type="button" class="verify_btn" :class="[{ grayText: lockBtn },'small']" :disabled="lockBtn" @click="clickToGetVerifyCode(2)">{{$t('sendVerificationByTextMsg')}} <span v-show="lockBtn">{{countDown}}</span></button>
                <button type="button" class="verify_btn" :class="[{ grayText: lockBtn },'small']" :disabled="lockBtn" @click="clickToGetVerifyCode(1)">{{$t('sendVerificationByMail')}} <span v-show="lockBtn">{{countDown}}</span></button>
              </div>
            </div>
            <div class="item100" v-if="!unlockStatus && !member.api_token">
              <p>{{$t('verificationInfo')}}<span class="txt-main">*</span></p>
              <div class="item_inner col100">
                <div class="col66">
                  <input
                    type="text"
                    :placeholder="this.$t('verificationAnswerPlaceholder')"
                    v-model="forCaptchaVerifyCode">
                </div>
                <div class="col33">
                  <img @click="clickChangeCaptcha" :src="forCaptchaVerifyImgData" alt="" style="margin: 7px auto;">
                </div>
              </div>
              <button
                type="button"
                class="btn"
                style="width: 100%; margin-top:7px;"
                @click="dragSuccess">
                {{$t('getVerification')}}
              </button>
              <!-- <drag-verify
                style="margin: auto"
                :width="240"
                :height="50"
                progressBarBg="#66cc66"
                text="滑動獲取驗證碼"
                success-text="解鎖成功"
                background="#af566de6"
                @passcallback="dragSuccess"
                ></drag-verify> -->
            </div>
          </form>
          <button
            v-if="unlockStatus || member.api_token"
            type="button"
            class="btn p"
            :class="{ btnDisable: isBtnDisable }"
            @click="startAppointment();">
            {{$t('nextStep')}}
          </button>
        </div>
      </div>
    </div>

    <div id="change_password_lightbox"
      v-show="changePasswordLightboxOpen">
      <div class="cover"></div>
      <div class="box">
        <div class="text txt-center">
          <h5 class="txt-bold">
            {{$t('changePassword')}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
          <p>{{$t('newPasswordInfo')}}</p>
          <form class="">
            <p class="small">{{$t('newPassword')}}</p>
            <div class="password_outer">
              <input
                :type="(ifPasswordAbleToSee2) ? 'text' : 'password'"
                @blur="passwordTest2"
                v-model="newPassword">
              <img src="@/assets/img/eye.jpg" alt="" class="eye"
                @click="ifPasswordAbleToSee2 = !ifPasswordAbleToSee2">
            </div>
            <p class="small">{{$t('comfirmNewPassword')}}</p>
            <div class="password_outer">
              <input
                :type="(ifPasswordAbleToSee3) ? 'text' : 'password'"
                @blur="passwordTest3"
                v-model="checkNewPassword">
              <img src="@/assets/img/eye.jpg" alt="" class="eye"
                @click="ifPasswordAbleToSee3 = !ifPasswordAbleToSee3">
            </div>
          </form>
          <button type="button"
            class="btn p"
            :class="{ btnDisable: isBtnDisable }"
            @click="clickToChangePassword();">
            {{$t('saveMod')}}
          </button>
        </div>
      </div>
    </div>

    <div id="foreigner_lightbox"
      v-show="foreignerLightboxOpen">
      <div class="cover" @click="closeForeignerLightBox"></div>
      <div class="box">
        <div class="text txt-center">
          <p>
            {{$t('hello')}}：<br>
            {{$t('overSeaServiceInfo')}}
          </p>
          <p>
            {{$t('IvfClinic')}} <a href="tel:02-2705-5288" class="contact_link">02-2705-5288</a><br>
            {{$t('TcmClinic')}}     <a href="tel:02-2705-5277" class="contact_link">02-2705-5277</a><br>
            <br>
            <br>
            {{$t('monday')}}～{{$t('thursday')}}：{{$t('am')}}08:00~{{$t('night')}}21:00<br>
            {{$t('friday')}}：      {{$t('am')}}08:00~{{$t('pm')}}17:00<br>
            {{$t('saturday')}}：      {{$t('am')}}08:00~{{$t('noon')}}12:00
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
.drag_verify .dv_text {
  color: #FFF!important;
}
</style>
<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
import RegistrationStep from '@/components/registration/RegistrationStep.vue';

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
// import dragVerify from 'vue-drag-verify';
import { isMobile } from 'mobile-device-detect';
import { isNationalIdentificationNumberValid } from 'taiwan-id-validator';

import { mapMutations, mapState, mapGetters } from 'vuex';
import {
  API_URL,
  getDoctor,
  getSchedule,
  returnZhDay,
  getCountryCode,
  getVerifyCode,
  getMemberProfile,
  signUp,
  changePassword,
  getCaptchaImg,
  captchaVerifyCode,
  getFirstSchedule,
  // eslint-disable-next-line
  checkMemberData,
} from '@/lib/public';

import pic01 from '@/assets/img/index/imglink_1.jpg';

// import pdf01 from '@/assets/img/宜蘊生殖醫學中心門診表.pdf';
// import pdf02 from '@/assets/img/宜蘊中醫門診表.pdf';

export default {
  name: 'RegistrationChoose',
  components: {
    BannerKv,
    RegistrationStep,
    DatePick,
    // dragVerify,
  },
  props: ['classification'],
  data() {
    return {
      isMobile,
      isBtnDisable: false,

      old_password: '',
      newPassword: '',
      checkNewPassword: '',
      changePasswordLightboxOpen: false,
      // changeEmailLightboxOpen: false,
      ifPasswordAbleToSee2: false,
      ifPasswordAbleToSee3: false,

      foreignerLightboxOpen: false,

      API_URL,
      isTaiwanId: false,
      lockBtn: false,
      countDown: 60,
      unlockStatus: false,

      swiperOptionPc: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '.swiper-pagination-pc',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-pc',
          prevEl: '.swiper-button-prev-pc',
        },
      },
      swiperOptionMobile: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '.swiper-pagination-m',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-mobile',
          prevEl: '.swiper-button-prev-mobile',
        },
      },
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01,
      },
      chooseDoctor: null,
      chooseSid: null,
      tempMobileChooseTimeControl: 0,
      DoctorData: [
        { memId: 0, name: '---' },
      ],
      now7days: [],
      nowWeek: 1,
      nowDoctorSchedule: [],
      nowDoctorScheduleMap: [
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
      ],
      nowDoctorScheduleMapMobile: [
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
      ],
      countryCode: [],
      registrationLightboxOpen: false,
      formData: {
        account: '',
        name: '',
        birthday: '',
        phone: '',
        email: '',
        country_code: '',
        verify_code: '',
        send_way: '',
      },
      file: '',
      ifFirst: true,
      forCaptchaVerifyCode: '',
      // forCaptchaVerifyKey: '',
      forCaptchaVerifyImgData: '',
    };
  },
  computed: {
    ...mapState([
      'member',
    ]),
    ...mapGetters([
      'getLangUUID',
      'getLanguage',
    ]),
  },
  watch: {
    chooseSid() {
      if (this.isMobile) {
        this.confirmHandler();
      }
    },
    tempMobileChooseTimeControl() {
      if (this.isMobile) {
        this.confirmHandler();
      }
    },
    getLangUUID(val) {
      if (val !== null) {
        this.getFile();
      }
    },
  },
  methods: {
    ...mapMutations([
      'setAppointmentData',
      'setSignUpFormData',
      'logIn',
      'setLangUUID',
    ]),
    passwordTest2() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (!passwordRegex.test(this.newPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
        this.newPassword = '';
      }
    },
    passwordTest3() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (!passwordRegex.test(this.checkNewPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
        this.checkNewPassword = '';
      }
    },
    clickToChangePassword() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (this.newPassword !== this.checkNewPassword) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordComfirmError') });
      } else if (!passwordRegex.test(this.newPassword) || !passwordRegex.test(this.checkNewPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
      } else {
        this.isBtnDisable = true;
        changePassword(
          this.$cookies.get('api_token'),
          {
            old_password: this.formData.birthday.replace(/-/g, ''),
            password: this.newPassword,
          },
          () => {
            this.$customSWAL({
              title: this.$t('modifySuccess'),
              thenFunc: () => {
                this.closeLightbox();
                this.$router.push('/registrationconfirm');
              },
            });
          },
          (message) => {
            this.$customSWAL({ icon: 'error', title: message });
          },
        );
        this.isBtnDisable = false;
      }
    },
    openLightbox() {
      this.changePasswordLightboxOpen = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox() {
      this.changePasswordLightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    openForeignerLightBox() {
      console.log('foreigner');
      this.foreignerLightboxOpen = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeForeignerLightBox() {
      this.foreignerLightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    inputAccount(e) {
      if (e.target.id === 'accA') {
        this.formData.accountB = '';
      } else {
        this.formData.accountA = '';
      }
    },
    dragSuccess() {
      captchaVerifyCode(
        {
          captcha: (this.forCaptchaVerifyCode === '') ? '' : Number(this.forCaptchaVerifyCode),
          // key: this.forCaptchaVerifyKey,
        },
        // 成功
        () => {
          this.unlockStatus = true;
        },
        // 失敗
        (message) => {
          if (message === 401) {
            this.$customSWAL({ icon: 'error', title: this.$t('verify401') });
          } else {
            this.$customSWAL({ icon: 'error', title: this.$t('verify400') });
          }
          getCaptchaImg()
            .then((response) => {
              console.log(response);
              // this.forCaptchaVerifyKey = response.key;
              this.forCaptchaVerifyImgData = response.data;
            });
        },
      );
      console.log('good!');
    },
    downloadPDF() {
      console.log('click');
      // axios.post('http')
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.$cookies.set('btnCountDown', this.countDown);
          this.countDownTimer();
        }, 1000);
      } else {
        this.lockBtn = false;
        this.countDown = 60;
        this.$cookies.set('btnCountDown', 60);
        this.unlockStatus = false;
        getCaptchaImg()
          .then((response) => {
            console.log(response);
            // this.forCaptchaVerifyKey = response.key;
            this.forCaptchaVerifyImgData = response.data;
          });
      }
    },
    restoreCountDown() {
      if (this.$cookies.isKey('btnCountDown')) {
        this.$cookies.set('btnCountDown', this.$cookies.get('btnCountDown'));
        this.countDown = this.$cookies.get('btnCountDown');
        if (this.countDown < 60) {
          this.lockBtn = true;
          this.countDownTimer();
          this.unlockStatus = false;
        }
      }
    },
    returnZhDay,
    checkId(id) {
      isNationalIdentificationNumberValid(id);
    },
    initDoctor() {
      getDoctor(this.classification)
        .then((response) => {
          // console.log('取得醫師', response.data);
          this.DoctorData = [];
          response.data.forEach((element) => {
            const temp = {
              memId: element.doctor_code,
              name: element.name,
              devisionId: element.devision_id,
            };
            this.DoctorData.push(temp);
          });
          if (this.DoctorData[0].devisionId === 1) {
            this.DoctorData = [
              this.DoctorData[0],
              this.DoctorData[1],
              this.DoctorData[3],
              this.DoctorData[2],
            ];
          }
        })
        .catch(() => {
          this.$customSWAL({ icon: 'error', title: this.$t('noDoctor') });
        });
    },
    initNow7day() {
      this.now7days = [];
      this.nowWeek = 1;
      for (let i = 0; i < 7; i += 1) {
        // === old 2/17前日期從2/17開始顯示===
        // let d = null;
        // if (new Date() <= new Date('2021/02/17')) {
        //   d = new Date('2021/02/17');
        // } else {
        //   d = new Date();
        // }
        const d = new Date();
        const d2 = new Date(d.setDate(d.getDate() + i));
        this.now7days.push(`${d2.getUTCFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`);
      }
      // console.log('NOW7DAYS', this.now7days);
    },
    changeWeek(direction) {
      switch (direction) {
        case 'prev':
          if (this.nowWeek > 1) {
            console.log('prev', this.nowWeek);
            this.nowWeek -= 1;
          }
          break;
        case 'next':
          if (this.nowWeek < 4) {
            console.log('next', this.nowWeek);
            this.nowWeek += 1;
          }
          break;
        default:
          break;
      }
      this.now7days = [];
      for (let i = 0; i < 7; i += 1) {
        const d = new Date();
        const d2 = new Date(d.setDate(d.getDate() + i + (7 * (this.nowWeek - 1))));
        this.now7days.push(`${d2.getUTCFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`);
      }
      this.startScheduleFilter();
    },
    confirmHandler() {
      if (!this.chooseDoctor) {
        this.$customSWAL({ icon: 'error', title: this.$t('selectDoctorInfo') });
      } else if (!this.chooseSid) {
        if (!this.isMobile) {
          this.$customSWAL({ icon: 'error', title: this.$t('selectTimeInfo') });
        }
      } else {
        const el = document.getElementsByTagName('body')[0];
        el.classList.add('lock');
        this.registrationLightboxOpen = true;
      }
    },
    registrationLightboxClose() {
      this.registrationLightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    startScheduleFilter() {
      // 清空排班
      this.nowDoctorSchedule = [];
      this.nowDoctorScheduleMap = [
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
      ];
      this.nowDoctorScheduleMapMobile = [
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
        [null, null, null],
      ];

      const submitData = {
        doctor_code: this.chooseDoctor,
        start_day: this.now7days[0],
        end_day: this.now7days[6],
      };
      this.chooseSid = null;

      getSchedule(
        submitData,
        (response) => {
          // this.nowDoctorSchedule = [];
          // this.nowDoctorScheduleMap = [
          //   [null, null, null, null, null, null, null],
          //   [null, null, null, null, null, null, null],
          //   [null, null, null, null, null, null, null],
          // ];
          // this.nowDoctorScheduleMapMobile = [
          //   [null, null, null],
          //   [null, null, null],
          //   [null, null, null],
          //   [null, null, null],
          //   [null, null, null],
          //   [null, null, null],
          //   [null, null, null],
          // ];
          response.data.forEach((elem) => {
            this.nowDoctorSchedule.push(elem);
          });

          // 依醫師, PC版資料
          this.nowDoctorSchedule.forEach((elem, k) => {
            // console.log(k, elem.short_day);
            for (let i = 0; i < this.now7days.length; i += 1) {
              // console.log(i, this.now7days[i]);
              if (elem.short_day === this.now7days[i]) {
                // console.log('!!!!!!!!!!!!!!');
                if (elem.shift_code === '1') {
                  this.nowDoctorScheduleMap[0].splice(i, 1, this.nowDoctorSchedule[k]);
                } else if (elem.shift_code === '3') {
                  this.nowDoctorScheduleMap[1].splice(i, 1, this.nowDoctorSchedule[k]);
                } else if (elem.shift_code === '4') {
                  this.nowDoctorScheduleMap[2].splice(i, 1, this.nowDoctorSchedule[k]);
                }
              }
            }
          });

          // 依醫師, Mobile版資料
          this.nowDoctorSchedule.forEach((elem, k) => {
            // console.log(k, elem.short_day);
            for (let i = 0; i < this.now7days.length; i += 1) {
              // console.log(k, this.now7days[i], elem.shift_code);
              if (elem.short_day === this.now7days[i]) {
                // console.log('!!!!!!!!!!!!!!', elem);
                if (elem.shift_code === '1') {
                  this.nowDoctorScheduleMapMobile[i].splice(0, 1, this.nowDoctorSchedule[k]);
                } else if (elem.shift_code === '3') {
                  this.nowDoctorScheduleMapMobile[i].splice(1, 1, this.nowDoctorSchedule[k]);
                } else if (elem.shift_code === '4') {
                  this.nowDoctorScheduleMapMobile[i].splice(2, 1, this.nowDoctorSchedule[k]);
                }
              }
            }
          });

          // console.log(this.nowDoctorSchedule);
        },
        (message) => {
          this.$customSWAL({ icon: 'error', title: message });
        },
      )
        // .then()
        .catch(() => {
          this.$customSWAL({ icon: 'error', title: this.$t('noDoctorSchedule') });
        });
    },
    startAppointment() {
      // const token = this.$cookies.get('api_token');
      // this.unlockStatus = false;
      const vaildId = isNationalIdentificationNumberValid(this.formData.accountA);

      if (this.formData.country_code.indexOf('+') === 0) {
        this.formData.country_code = this.formData.country_code.slice(1);
      }
      if (this.formData.phone.indexOf('0') === 0) {
        this.formData.phone = this.formData.phone.slice(1);
      }

      const signUpFormData = this.formData;
      const submitData = {
        item_id: this.classification,
        devision_id: this.classification,
        doctor_code: this.chooseSid ? this.chooseSid.split(',')[0] : null,
        appointment_day: this.chooseSid ? this.chooseSid.split(',')[1] : null,
        sid: this.chooseSid ? this.chooseSid.split(',')[2] : null,
        shift_code: this.chooseSid ? this.chooseSid.split(',')[3] : null,
        clinic: this.chooseSid ? this.chooseSid.split(',')[4] : null,
        doctor_name: this.chooseSid ? this.chooseSid.split(',')[5] : null,
      };
      // console.log(submitData, signUpFormData);

      if (!this.chooseDoctor) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALSelectDoctor') });
      } else if (!this.chooseSid) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALSelectTime') });
      } else if (!signUpFormData.name) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALFillName') });
      } else if (!signUpFormData.birthday) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALFillDate') });
      } else if (!signUpFormData.accountA && !signUpFormData.accountB) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALFillPid') });
      } else if (signUpFormData.accountA && !vaildId) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALPidError') });
      } else if (!signUpFormData.country_code) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALSelectNumberLocal') });
      } else if (!signUpFormData.phone) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALFillNumber') });
      } else if (!signUpFormData.email) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALFillMail') });
      } else if (!signUpFormData.send_way && !this.member.api_token) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALClickVerification') });
      } else if (!signUpFormData.verify_code && !this.member.api_token) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALFillVerification') });
      } else {
        signUpFormData.account = signUpFormData.accountA || signUpFormData.accountB;
        // console.log('signUpFormData.account: ', signUpFormData.account);
        this.setAppointmentData(submitData);
        this.setSignUpFormData(signUpFormData);
        if (this.member.api_token) {
          const el = document.getElementsByTagName('body')[0];
          el.classList.remove('lock');
          this.$router.push('/registrationconfirm');
        } else {
          this.isBtnDisable = true;
          // 檢查email和手機號碼是否和註冊時一致
          checkMemberData(
            {
              account: signUpFormData.account,
              email: signUpFormData.email,
              country_code: signUpFormData.country_code,
              phone: signUpFormData.phone,
            },
            // 檢查通過
            () => {
              signUp(
                signUpFormData,
                // 註冊成功
                (response) => {
                  console.log(response.data);
                  this.logIn(response.data);
                  if (response.data.member.pwd_changed_at) {
                    const el = document.getElementsByTagName('body')[0];
                    el.classList.remove('lock');
                    this.$router.push('/registrationconfirm');
                  } else {
                    this.openLightbox();
                  }
                },
                // 註冊失敗
                (message) => {
                  this.$customSWAL({ icon: 'error', title: message });
                },
              );
            },
            // 檢查不通過
            (message) => {
              this.$customSWAL(
                {
                  icon: 'error',
                  title: message,
                  thenFunc: () => {
                    this.isDisable = false;
                  },
                },
              );
            },
          );
          this.isBtnDisable = false;
        }
      }
      // if (
      //   // token
      //   submitData.item_id
      //   && submitData.devision_id
      //   && submitData.doctor_code
      //   && submitData.appointment_day
      //   && submitData.sid
      //   && signUpFormData.account
      //   && signUpFormData.name
      //   && signUpFormData.birthday
      //   && signUpFormData.phone
      //   && signUpFormData.email
      //   && signUpFormData.country_code
      //   && signUpFormData.verify_code
      //   && signUpFormData.send_way
      // ) {
      //   this.setAppointmentData(submitData);
      //   this.setSignUpFormData(signUpFormData);
      //   this.$router.push('/RegistrationConfirm');
      // } else if (
      //   submitData.item_id
      //   && submitData.devision_id
      //   && submitData.doctor_code
      //   && submitData.appointment_day
      //   && submitData.sid
      //   && signUpFormData.account
      //   && !signUpFormData.name
      //   && signUpFormData.birthday
      //   && signUpFormData.phone
      //   && signUpFormData.email
      //   && signUpFormData.country_code
      //   && signUpFormData.verify_code
      //   && signUpFormData.send_way
      // ) {
      //   this.$customSWAL({ icon: 'error', title: '請填寫姓名' });
      // } else {
      //   this.$customSWAL({ icon: 'error', title: '掛號資訊未填寫完成' });
      // }
      // else if (!token) {
      //   this.$customSWAL({ icon: 'error', title: '請先登入' });
      // }
    },
    clickChangeCaptcha() {
      getCaptchaImg()
        .then((response) => {
          console.log(response);
          // this.forCaptchaVerifyKey = response.key;
          this.forCaptchaVerifyImgData = response.data;
        });
    },
    clickToGetVerifyCode(sendWay) {
      if (sendWay === 1 && !this.formData.email) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALMailError') });
      } else if (sendWay === 2 && !this.formData.phone) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALPhoneError') });
      } else {
        this.countDownTimer();
        this.lockBtn = true;
        let countryCodeCut = this.formData.country_code;
        let phoneCut = this.formData.phone;
        if (this.formData.country_code.indexOf('+') === 0) {
          countryCodeCut = this.formData.country_code.slice(1);
        }
        if (this.formData.phone.indexOf('0') === 0) {
          phoneCut = this.formData.phone.slice(1);
        }
        getVerifyCode({
          phone: `${countryCodeCut}${phoneCut}`,
          email: this.formData.email,
          send_way: sendWay,
        }).then((result) => {
          if (result.status === 400) {
            this.$customSWAL({ icon: 'error', title: result.data.message });
          } else {
            this.$customSWAL({ title: this.$t('sendingVerification') });
            this.formData.send_way = sendWay;
          }
        });
        // getVerifyCode(
        //   {
        //     phone: this.formData.phone,
        //     email: this.formData.email,
        //     send_way: sendWay,
        //   },
        //   (result) => {
        //     console.log('xxx', result);
        //     // if (result.status) {
        //     //   this.$customSWAL({ title: '驗證碼已送出' });
        //     //   this.formData.send_way = sendWay;
        //     // } else {
        //     //   this.$customSWAL({ icon: 'error', title: result.message });
        //     // }
        //   },
        // );
      }
    },
    getFile() {
      getFirstSchedule(
        this.classification,
        this.getLangUUID,
        (response) => {
          console.log(response.data.data);
          this.file = response.data.data;
        },
      );
    },
  },
  created() {
    // console.log('cookie', this.$cookies.get('api_token'));
    if (this.$cookies.get('api_token')) {
      getMemberProfile(
        this.$cookies.get('api_token'),
        (response) => {
          // console.log(response.data.data);
          const userProfile = response.data.data;
          if (isNationalIdentificationNumberValid(userProfile.account)) {
            this.formData.accountA = userProfile.account;
            this.isTaiwanId = true;
          } else {
            this.formData.accountB = userProfile.account;
          }
          this.formData.birthday = userProfile.birthday;
          this.formData.country_code = userProfile.country_code;
          this.formData.email = userProfile.email;
          this.formData.name = userProfile.name;
          this.formData.phone = userProfile.phone;
        },
        // (response) => {
        //   this.$customSWAL({ icon: 'error', title: response });
        // },
      );
    }
  },
  mounted() {
    this.restoreCountDown();
    // console.log(this.classification);
    this.initDoctor();
    this.initNow7day();
    if (this.getLangUUID !== null) {
      this.getFile();
    }
    getCountryCode()
      .then((response) => {
        // console.log(response.data);
        this.countryCode = [];
        response.data.forEach((element) => {
          // console.log(element);
          this.countryCode.push(`+${element.code2}`);
        });
        const prepend = ['+886', '+86', '+852', '+81', '+1'];
        prepend.forEach((item) => {
          const index = this.countryCode.indexOf(item);
          if (index !== -1) {
            this.countryCode.splice(index, 1);
          }
        });
        this.countryCode = [...prepend, ...this.countryCode];
      });

    getCaptchaImg()
      .then((response) => {
        console.log(response);
        // this.forCaptchaVerifyKey = response.key;
        this.forCaptchaVerifyImgData = response.data;
      });
  },
};
</script>
